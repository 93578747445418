//Load Fonts from Local
require("@fontsource/mulish/600.css");
require("@fontsource/mulish/400.css");
require("@fontsource/mulish/200.css");
require("@fontsource/playfair-display/400.css");
// custom CSS styles
require('./src/css/bootstrap.css');
require('./src/css/style.css');

exports.shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
  }) => {
    const currentPosition = getSavedScrollPosition(location);
    setTimeout(() => { window.scrollTo(...(currentPosition || [0, 0])); }, 100);
  
    return false;
  };
  