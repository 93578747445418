exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-consultancy-tsx": () => import("./../../../src/pages/consultancy.tsx" /* webpackChunkName: "component---src-pages-consultancy-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-development-tsx": () => import("./../../../src/pages/development.tsx" /* webpackChunkName: "component---src-pages-development-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-marketing-and-content-tsx": () => import("./../../../src/pages/marketing-and-content.tsx" /* webpackChunkName: "component---src-pages-marketing-and-content-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-projects-bike-dock-solutions-tsx": () => import("./../../../src/pages/projects/bike-dock-solutions.tsx" /* webpackChunkName: "component---src-pages-projects-bike-dock-solutions-tsx" */),
  "component---src-pages-projects-dolphin-academy-tsx": () => import("./../../../src/pages/projects/dolphin-academy.tsx" /* webpackChunkName: "component---src-pages-projects-dolphin-academy-tsx" */),
  "component---src-pages-projects-marshmoor-bricks-tsx": () => import("./../../../src/pages/projects/marshmoor-bricks.tsx" /* webpackChunkName: "component---src-pages-projects-marshmoor-bricks-tsx" */),
  "component---src-pages-spacely-monitor-tsx": () => import("./../../../src/pages/spacely-monitor.tsx" /* webpackChunkName: "component---src-pages-spacely-monitor-tsx" */),
  "component---src-pages-web-technology-tsx": () => import("./../../../src/pages/web-technology.tsx" /* webpackChunkName: "component---src-pages-web-technology-tsx" */)
}

